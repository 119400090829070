<template>
  <div>
    <div class="mb-2"><strong>{{nameDisplayProp}}</strong></div>
    <span v-if="!saving">
      <div v-if="!edit" class="mb-2">
        {{value}}
        <b-button variant="outline-primary" size="sm" v-on:click="edit = true">Edit</b-button>
      </div>
      <b-form-input v-if="edit" id="input"
                  v-model="value"
                  :placeholder="nameDisplayProp"
                  required
                  class="mb-2">
      </b-form-input>
      <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
      <b-button v-if="edit" variant="outline-primary" size="sm" v-on:click="cancel">Cancel</b-button>
    </span>
    <span v-if="saving">
      <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PropertyInput',
  props: ['idProp', 'fieldProp', 'nameDisplayProp', 'modelProp', 'valueProp'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.edit && this.value
    }
  },
  created: function () {
    this.value = this.valueProp
    this.valueOld = this.value
  },
  data () {
    return {
      edit: false,
      value: '',
      valueOld: '',
      saving: false
    }
  },
  methods: {
    cancel: function () {
      this.value = this.valueOld
      this.edit = false
      this.saving = false
    },
    save: async function () {
      this.$logger.debug(`saving start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        const apiName = 'cosmos'
        const path = `/standard/${this.modelProp}/${this.idProp}`
        const body = {}
        body[this.fieldProp] = this.value
        let params = {
          body: body
        }
        const response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.valueOld = this.value
        this.edit = false
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style>
</style>
